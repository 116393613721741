import { useState } from "react"

import {
  HOME_GROUP_ADDRESS_FORM_ID,
  StepHomeGroupAddress,
} from "src/components/HomeGroups/HomeGroupAddressWizard/StepHomeGroupAddress"
import { StepHomeGroupMap } from "src/components/HomeGroups/HomeGroupAddressWizard/StepHomeGroupMap"
import {
  CREATE_HOMEGROUP_FORM_ID,
  HomeGroupNameStep,
} from "src/components/HomeGroups/HomeGroupsWizard/HomeGroupBuildingNameStep"
import { StepHomeGroupBuildingConfirm } from "src/components/HomeGroups/HomeGroupsWizard/StepHomeGroupBuildingConfirm"
import {
  usePostBuildingAddressConfirmed,
  usePostBuildingAddressInitiated,
  usePostCreateBuildingConfirmed,
  usePostCreateBuildingInitiated,
} from "src/data/analytics/queries/homegroupAnalyticsQueries"
import { usePostHomeGroup } from "src/data/homeGroups/queries/homeGroupQueries"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { ILocation } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

export interface IHomeOption {
  id: string
  name: string
}

export function HomeGroupBuildingWizard() {
  const { t, langKeys } = useTranslate()
  const { navigate, goBack } = useRouter()
  const [currentStep, setCurrentStep] = useState(0)
  const [homeGroupName, setHomeGroupName] = useState("")
  const { org } = useOrganization()
  const postHomeGroup = usePostHomeGroup()

  //#region Analytics
  const postCreateBuildingInitiated = usePostCreateBuildingInitiated()
  const postBuildingAddressInitiated = usePostBuildingAddressInitiated()
  const postBuildingAddressConfirmed = usePostBuildingAddressConfirmed()
  const postCreateBuildingConfirmed = usePostCreateBuildingConfirmed()
  //#endregion Analytics

  const [homeGroupAddress, setHomeGroupAddress] = useState<
    THomeGroup["address"] | undefined
  >()
  const [homeGroupLocation, setHomeGroupLocation] = useState<ILocation>({
    latitude: 0,
    longitude: 0,
  })

  function handleHomeAddressStep(
    address: THomeGroup["address"],
    location: ILocation
  ) {
    if (!address?.street_name1) return

    setHomeGroupAddress(address)
    setHomeGroupLocation(location)
    postBuildingAddressInitiated.mutate()
    setCurrentStep((currStep) => currStep + 1)
  }

  function createHomeGroup() {
    postCreateBuildingConfirmed.mutate()
    postHomeGroup.mutate(
      {
        orgId: org.id,
        body: {
          name: homeGroupName,
          address: homeGroupAddress,
          location: homeGroupLocation,
          type: "building",
        },
      },
      {
        onSuccess(hg) {
          navigate(Routes.HomeGroupDetails.path(hg.id))
        },
      }
    )
  }

  function handleHomeGroupNameStep(options?: { manualAddress: boolean }) {
    if (options?.manualAddress) {
      setHomeGroupAddress(undefined)
    } else {
      setHomeGroupAddress(homeGroupAddress)
    }
    setHomeGroupName(homeGroupName)
    postCreateBuildingInitiated.mutate()
    setCurrentStep((step) => step + 1)
  }

  function handleBuildingLocationStep() {
    postBuildingAddressConfirmed.mutate()
    setCurrentStep((currStep) => currStep + 1)
  }

  const stepper: IWizardStep[] = [
    {
      id: CREATE_HOMEGROUP_FORM_ID,
      component: (
        <HomeGroupNameStep
          homeGroupName={homeGroupName}
          setHomeGroupName={setHomeGroupName}
          setHomeGroupAddress={setHomeGroupAddress}
          isError={postHomeGroup.isError}
          onSubmit={handleHomeGroupNameStep}
        />
      ),
      backButtonLabel: t(langKeys.cancel),
      onBack: () =>
        goBack({ defaultPath: Routes.HomeGroupBuildings.location() }),
      nextButtonProps: { form: CREATE_HOMEGROUP_FORM_ID },
      nextButtonLabel: t(langKeys.continue),
      hideProgress: true,
      hideBackButton: true,
    },
    {
      component: (
        <StepHomeGroupAddress
          type="building"
          onSuccess={handleHomeAddressStep}
          address={homeGroupAddress}
          location={homeGroupLocation}
          required
        />
      ),
      nextButtonProps: { form: HOME_GROUP_ADDRESS_FORM_ID },
      nextButtonLabel: t(langKeys.continue),
      hideProgress: true,
    },
    {
      component: (
        <StepHomeGroupMap
          onChangeLocation={setHomeGroupLocation}
          location={homeGroupLocation}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
      onNext: handleBuildingLocationStep,
      hideProgress: true,
    },
    {
      component: (
        <StepHomeGroupBuildingConfirm
          address={homeGroupAddress!}
          location={homeGroupLocation}
          error={postHomeGroup.error}
          onEdit={() => setCurrentStep((step) => step - 2)}
        />
      ),
      nextButtonLabel: t(langKeys.homegroups_create_building_button_label),
      nextButtonProps: { loading: postHomeGroup.isLoading },
      onNext: () => createHomeGroup(),
      hideProgress: true,
    },
  ]

  const wizardTitle = `${t(langKeys.homegroup_building_wizard_title)}${homeGroupName ? ": " : ""}${homeGroupName}`
  return (
    <FullscreenWizard
      title={wizardTitle}
      steps={stepper}
      currentStep={currentStep}
      onClose={() => navigate(Routes.HomeGroupBuildings.location({}))}
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}
